<template>
  <v-dialog
    persistent
    v-model="openModal"
    max-width="550"
    transition="dialog-bottom-transition"
  >
    <v-container class="white rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="$emit('closeModalPay')"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 my-2" justify="center">
        <v-img
          alt="ApoTalent"
          class="shrink mr-2"
          src="@/assets/apotalent/Isotipo black.svg"
          transition="scale-transition"
          width="95"
        />
      </v-row>
      <v-row class="align-center ma-0 pa-0" justify="center">
        <p class="text-h6 font-weight-bold secondary--text">
          {{ $t("operation.active") }}
        </p>
      </v-row>
      <v-row class="text-center align-center ma-0 pa-0 px-6" justify="center">
        <p class="textDescription font-weight-bold mb-0">
          {{ $t("operation.select") }}
        </p>
      </v-row>
      <v-radio-group v-model="service">
        <v-row class="" justify="center">
          <v-col cols="11" sm="8" md="9" offset="1">
            <v-radio :value="alquimiaService">
              <template v-slot:label>
                <div class="primary--text text-body-1">
                  {{ $t("operation.alquimia") }}
                </div>
              </template></v-radio
            >
          </v-col>
        </v-row>
        <alquimia />
        <v-row class="" justify="center">
          <v-col cols="11" sm="8" md="9" offset="1">
            <v-radio :value="magisterService">
              <template v-slot:label>
                <div class="primary--text text-body-1">
                  {{ $t("operation.magister") }}
                </div>
              </template></v-radio
            >
          </v-col>
        </v-row>
        <magister />
      </v-radio-group>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <a
            class="secondary--text font-weight-medium "
            style="text-decoration: underline;font-size:12px"
            @click="goToFaqs"
            >{{ $t("operation.faqs") }}
          </a>
        </v-col>
      </v-row>
      <v-row class="my-4" justify="center">
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="pa-0 ma-0 text-center font-weight-medium"
          v-if="service"
        >
          <div>
            <v-btn
              @click="emitconfirmPay(service)"
              color="secondary"
              class="text-capitalize"
              style="font-size: 16px"
              large
              rounded
            >
              {{ $t("services.contract_service") }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" class="pa-0 mt-2 text-center"  v-if="service">
          <div>
            <v-btn
              color="transparent"
              large
              rounded
              outlined
              class="customButton primary--text"
              @click="isDraft ? $emit('closeModalPay') : emitconfirmPay(service)"
            >
              {{ isDraft ? $t("field.close") : $t("operation.save_draft") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import config from "@/config";
import Alquimia from "@/components/shared/AlquimiaService.vue";
import Magister from "@/components/shared/MagisterService.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Alquimia,
    Magister
  },
  data() {
    return {
      service: null,
      services: [
        {
          name: this.$i18n.t("operation.alquimia"),
          value: config.CONSTANTS.SERVICES.ALQUIMIA
        },
        {
          name: this.$i18n.t("operation.magister"),
          value: config.CONSTANTS.SERVICES.MAGISTER
        }
      ]
    };
  },
  props: ["openModal", "isDraft"],
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" }),
    alquimiaService() {
      return config.CONSTANTS.SERVICES.ALQUIMIA;
    },
    magisterService() {
      return config.CONSTANTS.SERVICES.MAGISTER;
    }
  },
  methods: {
    emitconfirmPay(type) {
      this.$emit("confirmPay", type);
    },
    goToFaqs() {
      let routeData = this.$router.resolve({ name: "faqs" });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
.text {
  font-size: 12px;
  color: grey;
}
.textDescription {
  font-size: 18px;
  color: grey;
}
</style>
