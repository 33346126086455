<template>
  <v-dialog
    v-model="openModal"
    max-width="550"
    transition="dialog-bottom-transition"
    @click:outside="closeWelcome()"
  >
    <v-container class="background rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="closeWelcome()"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 my-2" justify="center">
        <v-img
          alt="ApoTalent"
          class="shrink mr-2"
          src="@/assets/apotalent/Isotipo black.svg"
          transition="scale-transition"
          width="95"
        />
      </v-row>
      <v-row
        justify="center"
        class="primary--text text-h4 ma-0 pa-0 mx-4 font-weight-semibold"
        >{{ keyValues[title] }}</v-row
      >
      <v-row class="secondary--text mx-4">{{ keyValues[text] }} </v-row>
      <div>
        <v-row class="mx-2 my-8" justify="center">
          <v-col
            cols="12"
            md="6"
            class="pa-0 mt-2 text-center font-weight-medium"
          >
            <div>
              <v-btn
                @click="$emit('save_draft', false)"
                color="secondary"
                style="font-size: 16px; text-transform:none"
                large
                rounded
              >
                {{ $t("operation.leave_without_save") }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pa-0 mt-2 text-center font-weight-medium"
          >
            <div>
              <v-btn
                @click="$emit('save_draft', true)"
                color="secondary"
                style="font-size: 16px; text-transform:none"
                large
                rounded
              >
                {{ $t("operation.save_draft") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["title", "text", "openModal"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" })
  },
  methods: {
    closeWelcome() {
      this.dialog = false;
      this.$emit("closeModal");
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
</style>
