<template>
  <v-container fluid>
    <layout :back="true" />
    <v-form ref="form" v-model="formValid" lazy-validation>
      <div class="mx-4">
        <v-row justify="center"
          ><v-col cols="12" sm="8" md="4">
            <span
              class="primary--text font-weight-light text-caption mt-1 pl-md-0"
              >{{ $t("register.step") + "1" + $t("register.of") + "1" }}
            </span>
          </v-col></v-row
        >
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">{{ $t("offer.create") }}</h3>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("offer.language_publication") }}</v-subheader
            >
            <v-select
              v-model="newOffer.publish_language"
              :items="languages"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.offer_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.name")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="newOffer.position"
              :items="positions"
              @change="updateTitle"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.name')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.required_title")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="newOffer.title"
              disabled
              :items="titles"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.required_title')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.specialization") }}
              <span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-select
              v-model="newOffer.expertise"
              :items="expertises"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :label="$t('field.specialization')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.contract_type")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="newOffer.contract_type"
              :items="contractTypes"
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required"
              :label="$t('offer.contract_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.working_type")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="newOffer.working_day"
              :items="workingDays"
              multiple
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required_boolean"
              :label="$t('offer.working_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.working_hours")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              :items="workingHours"
              v-model="newOffer.working_hours"
              item-value="[0]"
              item-text="[1]"
              hide-details="auto"
              :rules="required_boolean"
              :label="$t('field.working_hours')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.watch") }}
              <span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <!-- faltan traducciones -->
            <v-textarea
              :label="$t('field.watch_comments')"
              auto-grow
              rows="3"
              outlined
              hide-details
              maxlength="100"
              v-model="newOffer.watch"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 mt-6">
            <v-textarea
              :label="$t('field.working_hours_comment')"
              auto-grow
              outlined
              hide-details
              maxlength="100"
              v-model="newOffer.working_hours_comment"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.experience_required")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-radio-group
              class="pa-0 ma-0"
              v-model="newOffer.experience_required"
              row
              :rules="required_boolean"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.no_experience") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.yes_experience") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.experience_fields")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            ><v-select
              :items="experienceFields"
              item-value="id"
              multiple
              max-length="30"
              item-text="name"
              v-model="newOffer.experience_fields"
              hide-details="auto"
              :label="$t('field.experience_fields')"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="pa-0">
          <v-subheader class="pa-0 text-caption primary--text"
            ><span class="red--text text-subtitle-2">*</span
            >{{ $t("offer.experience_time") }}</v-subheader
          >
          <v-text-field
            v-model="newOffer.experience_time"
            name="input-10-1"
            :label="$t('offer.experience_time')"
            hide-details="auto"
            :rules="required"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.required_language")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              :items="requiredLanguages"
              v-model="required_languages"
              item-value="id"
              item-text="name"
              multiple
              hide-details="auto"
              :rules="languagesRequired"
              :label="$t('offer.required_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.opcional_language")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-select
              :items="optionalLanguages"
              v-model="optional_languages"
              item-value="id"
              item-text="name"
              multiple
              hide-details="auto"
              :label="$t('offer.opcional_language')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.remuneration_model")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              :items="compensationModel"
              v-model="newOffer.remuneration_model"
              item-value="[0]"
              item-text="[1]"
              :rules="required_boolean"
              hide-details="auto"
              :label="$t('field.remuneration_model')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="9" sm="6" md="3" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.range")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('offer.range_placeholder')"
              hide-details="auto"
              v-mask="'########'"
              outlined
              v-model="newOffer.salary"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" sm="2" md="1" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("offer.currency") }}</v-subheader
            >
            <v-select
              :items="coin"
              v-model="currency"
              hide-details
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.incentives")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-radio-group
              class="pa-0 ma-0"
              v-model="newOffer.incentives"
              row
              :rules="required_boolean"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.yes") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1 font-weight-normal">
                    {{ $t("field.no") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              v-model="newOffer.must_be_near"
              hide-details="auto"
              class="pa-0"
            >
              <template #label>
                <div class="primary--text text-subtitle-1 ma-0">
                  {{ $t("field.must_be_near") }}
                </div>
              </template>
            </v-checkbox>
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("offer.must_be_near_desription") }}</v-subheader
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.incorporation_date")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(newOffer.incorporation_date)"
                  :label="$t('field.incorporation_date')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :rules="required"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newOffer.incorporation_date"
                show-adjacent-months
                :min="todayDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 mb-n3 text-caption primary--text"
              >{{ $t("offer.video") }}
            </v-subheader>
            <v-row class="pa-0 ma-0" justify-sm="center">
              <v-col cols="8" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  large
                  outlined
                  rounded
                  @click="addVideo"
                  style="text-transform: none"
                >
                  {{ $t("video.attach") }}
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-file-input
              small-chips
              :class="newOffer.video != null ? '' : 'd-none'"
              id="video"
              :label="$t('video.attach')"
              truncate-length="15"
              v-model="newOffer.video"
              accept="video/*"
            ></v-file-input>
          </v-col>
        </v-row> -->
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-radio-group v-model="newOffer.gender_preference" row :rules="[]">
            <v-radio :value="1">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{ $t("offer.men") }}
                </div>
              </template></v-radio
            >
            <v-radio :value="2">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{ $t("offer.women") }}
                </div>
              </template></v-radio
            >
          </v-radio-group>
        </v-row> -->
      </div>
    </v-form>
    <v-row class="mx-2 my-8" justify="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            class="customButton primary--text"
            style="text-transform: none; font-size: 15px"
            @click="$router.go(-1)"
          >
            {{ $t("offer.cancel") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
        <div>
          <v-btn
            color="secondary"
            large
            rounded
            @click="validateOffer"
            style="text-transform: none; font-size: 15px"
          >
            {{ $t("offer.create") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <offer-confirm-pay
      @confirmPay="redirectToCheckout"
      @closeModalPay="closeModalPay"
      :isDraft="false"
      :openModal.sync="showConfirmPay"
    ></offer-confirm-pay>
    <LeaveCreateOffer
      title="leave_offer_create_title"
      text="leave_offer_create_text"
      @save_draft="saveDraft"
      @closeModal="closeLeaveOfferModal"
      :openModal="showLeaveModal"
    />
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";
import OfferConfirmPay from "@/components/modals/OfferConfirmPay.vue";
import LeaveCreateOffer from "@/components/modals/LeaveCreateOffer.vue";

import { mapActions, mapGetters } from "vuex";
import OffersService from "@/services/OffersService.js";
export default {
  components: {
    layout,
    OfferConfirmPay,
    LeaveCreateOffer,
  },
  data() {
    return {
      showConfirmPay: false,
      showLeaveModal: false,
      offerLeave: false,
      formValid: false,
      menu: false,
      newOffer: {
        typeOfService: null,
        position: null,
        expertise: null,
        must_be_near: false,
        watch: null,
        contract_type: null,
        working_day: null,
        title: null,
        experience_required: null,
        working_hours_comment: "",
        remuneration_model: null,
        incentives: null,
        working_hours: null,
        incorporation_date: null,
        salary: null,
        pharmacy: null,
        offer_languages: [],
        experience_fields: [],
      },
      optional_languages: [],
      required_languages: [],
      experienceField: [],
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      required_boolean: [
        (v) => v != null || this.$i18n.t("validation.required"),
      ],
      languagesRequired: [
        (v) => v.length != 0 || this.$i18n.t("validation.required"),
      ],
      stripe: null,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      currency: "€",
      coin: ["€", "$", "£"],
    };
  },
  async created() {
    this.getPositions();
    this.getTitles();
    this.getExpertises();
    await this.getLanguages();
    this.getWorkingHours();
    this.getCompensationModel();
    this.defaultLanguage();
    this.getWorkingDays();
    this.getContractTypes();
    this.getExperienceFields();
    this.newOffer.pharmacy = this.childId;
  },
  computed: {
    ...mapGetters({ positions: "core/positions" }),
    ...mapGetters({ titles: "core/titles" }),
    ...mapGetters({ workingDays: "core/workingDays" }),
    ...mapGetters({ expertises: "core/expertises" }),
    ...mapGetters({ languages: "core/languages" }),
    ...mapGetters({ contractTypes: "core/contractTypes" }),
    ...mapGetters({ pharmacy: "pharmacy/pharmacy" }),
    ...mapGetters({ experienceFields: "core/experienceFields" }),
    ...mapGetters({ workingHours: "core/workingHours" }),
    ...mapGetters({ compensationModel: "core/compensationModel" }),
    ...mapGetters({ childId: "user/childId" }),
    optionalLanguages() {
      return this.languages.filter(
        (elm) => !this.required_languages.includes(elm.id)
      );
    },
    requiredLanguages() {
      return this.languages.filter(
        (elm) => !this.optional_languages.includes(elm.id)
      );
    },
    todayDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.offerLeave) {
      this.showLeaveModal = true;
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({ createOffer: "offer/createOffer" }),
    ...mapActions({ getPositions: "core/getPositions" }),
    ...mapActions({ getTitles: "core/getTitles" }),
    ...mapActions({ getWorkingDays: "core/getWorkingDays" }),
    ...mapActions({ getExpertises: "core/getExpertises" }),
    ...mapActions({ getLanguages: "core/getLanguages" }),
    ...mapActions({ getExperienceFields: "core/getExperienceFields" }),
    ...mapActions({ getWorkingHours: "core/getWorkingHours" }),
    ...mapActions({ getCompensationModel: "core/getCompensationModel" }),
    ...mapActions({ getContractTypes: "core/getContractTypes" }),
    ...mapActions({ createLanguageOffer: "language/createLanguageOffer" }),
    refactorLanguages() {
      this.required_languages.map((r) => {
        if (!this.newOffer.offer_languages.some((elm) => elm.language === r)) {
          this.newOffer.offer_languages.push({
            language: r,
            required: true,
            offer: null,
          });
        }
      });
      this.optional_languages.map((r) => {
        if (!this.newOffer.offer_languages.some((elm) => elm.language === r)) {
          this.newOffer.offer_languages.push({
            language: r,
            required: false,
            offer: null,
          });
        }
      });
    },
    addVideo() {
      document.getElementById("video").click();
    },
    defaultLanguage() {
      const language = this.languages.filter((l) => {
        return l.name_es == "Español";
      });
      this.required_languages.push(language[0].id);
    },
    async saveDraft(data) {
      await (this.showLeaveModal = false);
      if (data) {
        this.redirectToCheckout(false);
      } else {
        this.offerLeave = true;
        this.$router.push({ name: "offers" });
      }
    },
    closeLeaveOfferModal() {
      this.showLeaveModal = false;
    },
    validateOffer() {
      if (this.$refs.form.validate()) {
        this.showConfirmPay = true;
      }
    },
    async addOffer() {
      if (this.$refs.form.validate()) {
        await this.refactorLanguages();
        let response = await this.createOffer(this.newOffer);
        this.offerLeave = true;
        return response;
      }
    },
    async redirectToCheckout(confirm) {
      this.newOffer.typeOfService = confirm;
      let response = await this.addOffer();
      if (response != undefined) {
        if (confirm != false && response.status === 201) {
          // eslint-disable-next-line no-undef
          if (this.stripeAPIToken !== "") {
            // eslint-disable-next-line no-undef
            var stripe = Stripe(this.stripeAPIToken);
            OffersService.pay({
              stripe: stripe,
              offer_id: response.data.id,
              product: confirm,
            }).catch((err) => alert(err));
          } else {
            this.$router.push({
              name: "pharmacy-detail",
              params: { offer_id: response.data.id },
            });
          }
        } else {
          if (response.status === 201) {
            this.$router.push({
              name: "pharmacy-detail",
              params: { offer_id: response.data.id },
            });
          }
        }
      }
    },
    updateTitle() {
      this.newOffer.title = this.positions.find(
        (elm) => elm.id == this.newOffer.position
      ).associated_title;
    },
    allowedDates() {
      return new Date();
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.newOffer.incorporation_date = `${day}/${month}/${year}`;
    },
    closeModalPay() {
      this.newOffer.offer_languages = [];
      this.showConfirmPay = !this.showConfirmPay;
    },
    formatDate(date) {
      return date ? date.split("-").reverse().join("/") : null;
    },
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(recaptchaScript);
  },
};
</script>
<style lang="scss" scoped>
.customButton {
  box-shadow: none;
}
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
</style>
